import React from 'react';

import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import {withRouter} from "react-router-dom";

class ContactForm extends React.Component {

    state = {
        name: "",
        email: "",
        property: "",
        phone: "",
        message: "",
        recaptcha: false,
        success: false,
        error: false
    };

    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();

        axios.post("https://api.plumproperties.im/mail.php", {
            from: "noreply@plumproperties.im",
            to: "hello@plumproperties.im",
            template: "plum-properties-contact",
            vars: {
                type: this.props.heading,
                yourName: this.state.name,
                yourEmail: this.state.email,
                message: this.state.message,
                phone: this.state.phone,
                property: this.state.property
            }
        }).then((res) => {
            this.setState({
                success: true
            });
            window.location.href = '/sell-your-property/thank-you';
            ReactPixel.track("CompleteRegistration", null);
        }).catch((error) => {
            console.log(error);
            this.setState({
                error: true
            })
        });
    };

    onChange = (token) => {
        axios
            .post('verify', {
                response: token
            })
            .then((res) => {
                this.setState({
                    recaptcha: res.data
                })
            })
            .catch((error) => {
                console.log(error.message)
            })
    };

    render() {

        let disabled = false;
        if(this.state.name.length < 1) disabled = true;
        if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email)) disabled = true;
        if(this.state.message.length < 1) disabled = true;
        if(!this.state.recaptcha) disabled = true;

        return (
            <section className={this.props.primary ? "primary" : null} id="anchor">
                <div className="container contact">
                    <div>
                        <div>
                            <h2>{this.props.heading}</h2>
                            <p>{this.props.subheading}</p>
                            <p>Phone: <a href="tel:01624 820600">01624 820600</a></p>
                            <p>Email: <a href="mailto:hello@plumproperties.im">hello@plumproperties.im</a></p>
                            <p>Address: First Floor, St Georges Chambers, 1 Athol Street, Douglas, IM1 1LD</p>
                            <div className="icons">
                                <a href="https://www.facebook.com/PlumPropertiesIOM/" target="_blank" rel="noopener">
                                    <img src={require(`../../images/social/facebook${this.props.primary ? "-white" : ""}.svg`)} alt="Facebook" />
                                </a>
                                <a href="https://www.linkedin.com/company/plumproperties/about/" target="_blank" rel="noopener">
                                    <img src={require(`../../images/social/linkedin${this.props.primary ? "-white" : ""}.svg`)} alt="LinkedIn" />
                                </a>
                                <a href="https://www.instagram.com/plumproperties_isleofman/" target="_blank" rel="noopener">
                                    <img src={require(`../../images/social/instagram${this.props.primary ? "-white" : ""}.svg`)} alt="Instagram" />
                                </a>
                            </div>
                        </div>
                        <img src={require('../../images/logo-white.svg')} />
                    </div>
                    { this.state.error ?
                        <p className="sent">There was an error sending your message, please try again.</p>
                    :
                        this.state.success ?
                            <p className="sent">Thank you for getting in touch, we have received your message and will be in touch soon.</p>
                        :
                            <form onSubmit={this.handleSubmit.bind(this)}>
                                <input type="text" name="name" placeholder="Full Name" value={this.state.name} onChange={this.updateState.bind(this)} />
                                <input type="text" name="email" placeholder="Email Address" value={this.state.email} onChange={this.updateState.bind(this)} />
                                {this.props.appraisal && <input type="text" name="property" placeholder="Property Address (to be valued)" value={this.state.property} onChange={this.updateState.bind(this)} />}
                                {this.props.appraisal && <input type="text" name="phone" placeholder="Phone Number" value={this.state.phone} onChange={this.updateState.bind(this)} />}
                                <textarea name="message" placeholder="Message" value={this.state.message} onChange={this.updateState.bind(this)}></textarea>
                                <ReCAPTCHA
                                    sitekey="6Lf7dc4UAAAAAK23X3vcZhaLK1phSt9OmRAGdRaM"
                                    onChange={this.onChange.bind(this)}
                                />
                                <button disabled={disabled} className={this.props.primary ? "btn btnWhite" : "btn"}>Send Enquiry</button>
                            </form>
                    }
                </div>
            </section>
        );
    }
}

export default withRouter(ContactForm);
